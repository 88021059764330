import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EventIcon from "@mui/icons-material/Event";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import GppGoodIcon from "@mui/icons-material/GppGood";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AlertSkelton from "./skeltons/alerts.js";
import { getAlerts, removeAlert } from "../../redux/messageAndAlerts";
import { markReadNotificationsAll } from "../../redux/messageAndAlerts/markReadAll.js";
import { markAsUnreadAlert } from "../../redux/messageAndAlerts/markAsUnread.js";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { Link } from "react-router-dom";
import { CustomTooltip } from "../shared/tooltip.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircleIcon from "@mui/icons-material/Circle";
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import comingSoonIcon from "../../assets/images/alertIcons/coming-soon.png";
import thumbIcon from "../../assets/images/alertIcons/thumbIcon.png";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export const capitalize = (s) =>
  s && s[0] && s[0].toUpperCase() + s.slice(1)?.toLowerCase();

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0.5 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Icons = {
  lead_claim: (
    <PriceCheckRoundedIcon sx={{ fontSize: "27px", color: "#4CAF50" }} />
  ),
  shared_property_via_email: (
    <PriceCheckRoundedIcon sx={{ fontSize: "27px", color: "#4CAF50" }} />
  ),
};
let IconList = [
  {
    icon: <ErrorOutlineIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <EventIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <OndemandVideoIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <GppGoodIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <GppGoodIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
  {
    icon: <CelebrationIcon sx={{ fontSize: "27px", color: "grey" }} />,
  },
];
function AlertWidget(props) {
  let {
    classes,
    openAlert,
    setOpenAlert,
    adminAuth,
    unreadAlertCount,
    setUnreadAlertCount,
  } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 8,
  });
  const [value, setValue] = React.useState(0);
  const [showUnReads, setShowUnReads] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.MessagesAndAlerts.alerts);

  useEffect(() => {
    if (openAlert) {
      dispatch(
        getAlerts({
          ...filters,
          showUnreadedOnly: showUnReads,
        })
      );
    }
  }, [openAlert, filters.pageNumber, showUnReads]);

  const handleReturnIcon = (alertDetail, index) => {
    if (alertDetail?.project_id || alertDetail?.task_id) {
      if (alertDetail?.body?.includes("@")) {
        return <AccountCircleIcon sx={{ fontSize: "30px", color: "grey" }} />;
      } else {
        return <TaskAltRoundedIcon sx={{ fontSize: "27px", color: "grey" }} />;
      }
    }
    if (alertDetail?.body?.includes("@")) {
      return <AccountCircleIcon sx={{ fontSize: "30px", color: "grey" }} />;
    }
    return IconList[index]?.icon || IconList[3]?.icon;
  };
  const handleRedirect = (alertDetail) => {
    if (alertDetail?.lead_id) {
      navigate(`/leads/people/details/${alertDetail?.lead_id}`);
    }
    if (alertDetail?.recruitment_id) {
      navigate(`/tools/recruiting/details/${alertDetail?.recruitment_id}`);
    }
    if (alertDetail?.project_id) {
      navigate(`/projects`, {
        state: { project_id: alertDetail?.project_id },
      });
    }
    if (alertDetail?.task_id) {
      navigate(`/projects`, {
        state: { task_id: alertDetail?.task_id },
      });
    }
    if (alertDetail?.ticket_id) {
      navigate(`/agent_tickets/assigned`, {
        state: { ticket_id: alertDetail?.ticket_id },
      });
    }
    setOpenAlert(false);
  };

  const handleUpdateStatus = async (id, id1, type) => {
    if (type === "unread") {
      await dispatch(markAsUnreadAlert({ id, id1 }));
      if (unreadAlertCount === 0) {
        setUnreadAlertCount(1);
      }
    } else {
      await dispatch(markReadNotificationsAll({ id }));
      let seenAlerts = alerts?.data?.filter(
        (it) =>
          it?.notified_users?.find(
            (user) => user?.user_id === adminAuth?.adminDetail.id
          )?.is_seen
      )?.length;

      if (
        seenAlerts === alerts?.data?.length ||
        seenAlerts + 1 === alerts?.data?.length
      ) {
        setUnreadAlertCount(0);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMarkAsRead = async () => {
    await dispatch(markReadNotificationsAll());
    if (unreadAlertCount > 0) {
      setUnreadAlertCount(0);
    }
  };

  return (
    <Box className={classes.alertsArea}>
      <Box className={classes.AlertAreaHeader}>
        <Typography>Alerts</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showUnReads}
              onChange={(e) => {
                if (!alerts.isLoading) {
                  setShowUnReads(e.target.checked);
                }
              }}
            />
          }
          label="Show only unread alerts"
          labelPlacement="start"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "14px !important",
              color: "rgba(0, 0, 0, 0.60)  !important",
            },
          }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit" // Use inherit to control color via `sx`
            TabIndicatorProps={{
              style: {
                backgroundColor: "black", // Black indicator
              },
            }}
            sx={{
              "& .MuiTab-root": {
                textTransform: "none", // Camel case (prevents uppercase text)
                fontSize: "0.875rem", // Smaller font size
                minWidth: "80px", // Adjust tab size
                padding: "6px 12px",
              },
            }}
          >
            <Tab
              label={
                <Box>
                  {" "}
                  {alerts?.data?.filter(
                    (it) =>
                      it?.notified_users?.find(
                        (user) => user?.user_id === adminAuth?.adminDetail.id
                      )?.is_seen
                  )?.length < alerts?.data?.length ? (
                    <CircleIcon
                      fontSize="small"
                      sx={{ color: "red", fontSize: "10px", mr: "7px" }}
                    />
                  ) : null}
                  Direct
                </Box>
              }
              {...a11yProps(0)}
            />
            <Tab label="Watching" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className={classes.tabSubHeader}>
            <Typography className={classes.markAsHeading}>LATEST</Typography>
            <Button
              variant="text"
              size="small"
              className={classes.markAllAsRead}
              onClick={handleMarkAsRead}
            >
              Mark all as read
            </Button>
          </Box>
          <List disablePadding className={classes.textMessagesList}>
            {alerts?.data?.length
              ? alerts?.data.map((item, index) => (
                  <ListItem key={index} divider disablePadding>
                    <ListItemButton
                      sx={{
                        paddingRight: "4px",
                        "&:hover": {
                          "& .closeActionItem": { display: "flex !important" },
                        },
                      }}
                    >
                      <ListItemAvatar
                        onClick={() => handleRedirect(item)}
                        sx={{ minWidth: "40px" }}
                      >
                        {handleReturnIcon(item, index)}
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ width: "80%" }}
                        onClick={() => handleRedirect(item)}
                        classes={{
                          secondary: classes.textMessagesListItemContent,
                        }}
                        primary={
                          <Stack
                            direction={"row"}
                            spacing={1}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{ width: "87%" }}
                          >
                            {" "}
                            <Typography
                              className={classes.alertListItemHeading}
                            >
                              {capitalize(item.title)}
                            </Typography>{" "}
                            <Typography
                              className={classes.textMessagesListItemTime}
                            >
                              {momentTZ(item.updatedAt)
                                .fromNow()
                                .replace("minutes", "min")
                                .replace("minute", "min")
                                .replace("hours", "hrs")
                                .replace("hour", "hr")}
                            </Typography>
                          </Stack>
                        }
                        secondary={
                          <Linkify
                            properties={{ target: "_blank" }}
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                              </a>
                            )}
                          >
                            {reactStringReplace(
                              item?.body || "",
                              /@\[(.*?)]/g,
                              (match, i) => (
                                <CustomTooltip
                                  title={match?.split("^")?.[0]}
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      color:
                                        match?.split("^")?.[1] ===
                                        adminAuth?.adminDetail.id
                                          ? "#007EA6 !important"
                                          : "#434343 !important",
                                      background:
                                        match?.split("^")?.[1] ===
                                        adminAuth?.adminDetail.id
                                          ? "#CCF3FF !important"
                                          : "#ECECEC !important",
                                      display: "inline-block",
                                      textDecoration: "none",
                                    }}
                                    key={i}
                                    to={`/agent_roster/${
                                      match?.split("^")?.[1] || 69
                                    }/overview`}
                                    component={Link}
                                  >
                                    @{match?.split(" ")?.[0]}
                                  </Box>
                                </CustomTooltip>
                              )
                            )}
                          </Linkify>
                        }
                      />

                      <ListItemSecondaryAction sx={{ mr: "-5px", mt: "-8px" }}>
                        {item?.notified_users?.find(
                          (user) => user?.user_id === adminAuth?.adminDetail.id
                        )?.is_seen ? (
                          <IconButton
                            onClick={() => {
                              handleUpdateStatus(
                                item.id,
                                item?.notified_users?.find(
                                  (user) =>
                                    user?.user_id === adminAuth?.adminDetail.id
                                )?.id,
                                "unread"
                              );
                            }}
                            size="small"
                            title="Mark as unread"
                            className="closeActionItem"
                            sx={{ display: "none", mt: "3px" }}
                          >
                            <CircleOutlinedIcon
                              fontSize="small"
                              sx={{ color: "grey", fontSize: "18px" }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              handleUpdateStatus(item.id, null, "read");
                            }}
                            size="small"
                            title="Mark as read"
                            sx={{ ml: "-24px !important" }}
                          >
                            <CircleIcon
                              fontSize="small"
                              sx={{ color: "red", fontSize: "10px" }}
                            />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
            {alerts.isLoading ? <AlertSkelton /> : null}
            {!alerts?.data?.length && !alerts.isLoading ? (
              <Box className={classes.TextMessageAreaNoExist}>
                <center>
                  <img src={thumbIcon} alt={thumbIcon} />
                  <Typography>You’re all caught up.</Typography>
                </center>
              </Box>
            ) : null}
          </List>
          {alerts.count > filters.pageNumber * filters.pageSize ? (
            <Box>
              <Button
                variant="text"
                size="small"
                fullWidth
                onClick={() =>
                  setFilters({ ...filters, pageNumber: filters.pageNumber + 1 })
                }
                sx={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                disabled={alerts.isLoading}
              >
                See More
              </Button>
            </Box>
          ) : alerts.count > 0 ? (
            <Typography
              sx={{
                color: "#979797",
                fontSize: "14px",
                textAlign: "center",
                height: "35px",
                lineHeight: "35px",
              }}
            >
              That's all your alerts for the last 30 days
            </Typography>
          ) : null}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "370px",
            }}
          >
            <img
              src={comingSoonIcon}
              alt="comingSoonIcon"
              objectFit="contain"
              style={{ width: "160px" }}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AlertWidgetStyle" })(
  AlertWidget
);
